import React from "react"

import { PageLayout } from "@components/layouts"
import Seo from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import ParallaxSection from "@components/parallaxSection"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const WebsiteDesignSEOServicePage = () => {
  return (
    <PageLayout>
      <Seo
        title="Mobile App Development Company - iOS and Android - Websuasion"
        ogDescription="We are a mobile app development company building iOS and Android apps for business. Starting with blueprint design, we take you to deployment and beyond."
        image="/images/development/mobile-app-development-companies.jpg"
      />
		  <TextBlock
		    textPadded
		    textLeft={
		      <div>
						<h1>Mobile App Development Company</h1>
						<p>
							We are a mobile app development company building hybrid, cross-platform apps for <a href="https://www.apple.com/ios/">Apple iOS</a> &amp; <a href="https://www.android.com/">Google Android</a>. This approach allows you to build once and deploy twice. It saves you the cost of duplicate native development teams. It also ensures a consistent user experience across platforms. We are <a href="https://websuasion.com/about-our-app-developers/">experts in building scalable applications</a> optimized for mobile. We focus on making apps that become part of the daily routine of your staff and customers. We focus on apps that improve your bottom line.
						</p>
						<FormWrapper
		          modal
		          buttonProps={{
		            content: "Schedule An Introductory Call",
		            size: "tiny",
		            icon: "calendar alternate outline",
		          }}
		        >
		          <RequestMoreInfo />
		        </FormWrapper>
		      </div>
		    }
		    textRight={
		      <div>
					<img src="/images/development/mobile-app-development-companies.jpg" className="rounded-image" alt="Mobile App Development Company - iOS and Android - Websuasion" />
		      </div>
		    }
		  />
			<PageWrapper color="charcole">
			  <TextBlock
			    textPadded
			    textLeft={
		        <div>
							<h2>An Atlanta Mobile App Development Company</h2>
							<p>
								We are a 100% Atlanta-based mobile app development company. In fact, we require our developers to be in the same room. <a href="https://en.wikipedia.org/wiki/Pair_programming">Pair-programming</a> and collective decision making allow us to be productive. We tried working remote development in the past. It was slow and frustrating. Add time zone and language barriers on top of that and it's not practical.
							</p>
							<p>
								That said, it doesn't mean our clients have to be in Atlanta. In fact, <a href="/case-studies/">most are not</a>. We can work with you over video conferencing and typically meet via Zoom.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/atlanta-mobile-developers.jpg" className="rounded-image" alt="Atlanta Mobile App Development Company - iOS and Android - Websuasion" />
			      </div>
			    }
			  />
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Are We A Good Fit?</h2>
							<p>
								We need to first make sure we are a good fit for one another. We prefer to work with established businesses. Do you have a staff and a healthy customer base? Are you so busy you don't know where to turn? Then we are the mobile app development company for you.
							</p>
							<p>
								We are business people first, developers second, and visual artists third. That culminates in highly-usable, practical apps targeted to improve your bottom line. We aren't trying to win design awards for creativity. We focus on making you more money.
							</p>
							<h2>Learning Your Business Niche</h2>
							<p>
								Whether your business is B2B &amp; B2C, we can identify the pain points of friction in your business. We learn what makes you different. We find your competitive advantage. Then we help you to exploit that advantage using technology.
							</p>
							<p>
								Mobile apps can increase productivity in the field. Some solutions include:
							</p>
							<ul>
							 	<li>Mapping, Geolocation and Geofencing</li>
							 	<li>Time Tracking and Calendars</li>
							 	<li>Task Management</li>
							 	<li>Quality Checklists</li>
							 	<li>Payment Processing</li>
							 	<li>Signing Contracts</li>
							 	<li>Quoting</li>
							</ul>
						</div>
	        }
	      />
			</PageWrapper>
      <ParallaxSection
        src={`/images/development/mobile-app-niche-business.jpg`}
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Own Your Implementation</h2>
							<p>
								Upon full payment, you will own your implementation of our code. You can do what you like with it. We give you full access to the codebase. We provide everything you need to move forward. We strive to be there for you on an ongoing basis, but that doesn't mean we're tethered. If you create your own internal support staff, we are happy to guide you through the transition.
							</p>
							<h2>Concept Appraisal</h2>
							<p>
								You have a great app idea. But, have you done the research? Is there competition? If so, that's actually a good thing. But, where are your competitors dropping the ball? Are they missing a niche? How big is the potential user base? How much can you charge? How should you deploy? These are all things we can help you to answer. Sit down with us for a product development consultation.
							</p>
							<h2>Ideas are great, but ...</h2>
							<p>
								Ideas are great, but solving real business problems is better. We are experts at finding opportunities in your existing workflow. We look for places to save money and create new revenue streams. There's no need to daydream. Uncover existing opportunities from actual metrics and you'll anticipate what the market needs.
							</p>
							<h2>Execution Matters. Deliverables Matter.</h2>
							<p>
								The most cutting edge design won't amount to a hill of beans if it never sees the light of day. You need your mobile app completed on time, on budget and to your specifications out of the gate. Achieving that requires a firm with a knack for long-range planning. It requires a firm who gives you direct answers and recommendations. No nonsense. No sugar coating. "This is a great idea." "That is not." That's how we approach every client relationship.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
      <ParallaxSection
        src={`/images/development/mobile-app-architecture.jpg`}
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Blueprints and Planning</h2>
							<p>
								It would not be wise to build a house without blueprints. Likewise, it's unwise to build any kind of mobile app without application architecture. Much of what you see in mobile apps isn't happening on the phone. It's happening up in the cloud. The mobile app passes data and commands to your cloud server. The cloud server houses your database and all the business logic that gets things done. We spend a lot of time with you making sure we design a cloud application to scale with your business.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
			  <TextBlock
			    textPadded
			    textLeft={
		        <div>
							<h2>HTML5 Prototyping</h2>
							<p>
								Once we have the architecture in place, it's time to design the look and feel of your mobile app. How should your staff and customers interact with it? How should the navigation work? How many screens should there be? These are all questions answered in the prototyping phase. We generate prototypes using HTML5 for fast iterative changes. In fact, if you are in Atlanta, you can sit with our UI/UX developer and see changes happen before your eyes. It's pretty cool.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/mobile-app-prototypes.jpg" className="rounded-image" alt="Software Development Company - Custom Programming - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Fast Iterative Development Process</h2>
							<p>
								Our development process allows for quick iteration on the server-side. We design your mobile app to rely on your more agile cloud server. Your cloud server is where the data and logic resides. Your mobile app is a customized interface for that centralized server. Your mobile users don't have to download a new version of your app every time there is a small change. Minimal mobile deployment. Frequent server deployment. That's how we roll.
							</p>
							<h2>API and Administrative Implementations</h2>
							<p>
								Your mobile app isn't only a mobile app. You will at least need an <a href="https://en.wikipedia.org/wiki/Web_API">API server</a> with an <em>Administration portal</em>. The API provides your mobile app with data. The Administration portal allows you to manage users, add content, and pull reports. In fact, most mobile app development cost is on the server side.
							</p>
							<h2>Integration with 3rd-party APIs</h2>
							<p>
								Using existing services and want to connect your mobile app to them? No problem. We can integrate with any cloud service that provides API access. This includes:
							</p>
							<ul>
							 	<li>Google (Maps, Ads, Captcha, etc.)</li>
							 	<li>Quickbooks Cloud</li>
							 	<li>Salesforce</li>
							 	<li>Hubspot</li>
							 	<li>Twitter</li>
							 	<li>Facebook</li>
							 	<li>AuthorizeNet</li>
							 	<li>Stripe</li>
							</ul>
							<h2>Secure Cloud Infrastructure</h2>
							<p>
								We deploy your app on managed <a href="https://en.wikipedia.org/wiki/Virtual_private_server">VPS servers</a>. We use stable Ubuntu operating systems. We ensure your app communicates through <a href="https://en.wikipedia.org/wiki/Transport_Layer_Security">TLS 1.2 or higher SSL connections</a>. We encrypt browser cookies. We lock down your server from unnecessary outside connections.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
			  <TextBlock
			    textPadded
			    textLeft={
		        <div>
							<h2><a href="/development/software-development-company/">Quality Assurance Testing</a></h2>
							<p>
								We know you are busy. You don't have the time or experience necessary to test every detail of your application. So, we get as much feedback as we can from you. But we do our own vigorous quality assurance testing of your app. <a href="https://websuasion.com/about-our-app-developers/">Our QA testers</a> do everything they can to break your app. When they succeed, our developers fix the issue. You don't end up 3 months after a release with frustrated customers.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/mobile-app-qa-testing.jpg" className="rounded-image" alt="Software Development Company - Custom Programming - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
      <ParallaxSection
        src={`/images/development/mobile-app-api-integration.jpg`}
      />
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Apple App Store</h2>
							<p>
								Apple has a relentless approval process. It can sometimes take up to 60 days to get certain apps approved. We design around Apple's restrictions and best practices. This keeps revision demands by Apple to a minimum. We navigate all the Apple red tape for you. We do all we can to set you up for successful approval.
							</p>
							<h2>Google Play</h2>
							<p>
								Google is much more laid back with regard to Android app approval. We can usually have your finished app live within 24 hours. In fact, if you are building an internal app for staff, we don't even need to go through Google. We can deploy right to your devices. Easy.
							</p>
							<h2>Metrics</h2>
							<p>
								Mobile apps give us the ability to collect useful metrics about your staff and customers. We work with your business strategist to measure your Key Performance Indicators (KPI). So you know if you are achieving your goals. And if not, you know what to change.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="charcole">
			  <TextBlock
			    textPadded
			    textLeft={
		        <div>
							<h2><a href="https://websuasion.com/digital-marketing-agency-branding-firm/">Marketing and Advertising</a></h2>
							<p>
								Building your app is only half of the work. If you don't already have an established customer base, you have to <a href="https://websuasion.com/digital-marketing-agency-branding-firm/">promote your app</a>. We can put in place powerful lead funnels and multi-tier advertising campaigns. We guide you through their successful implementation. We can help your list-building efforts to ensure people know and love what you do.
							</p>
							<h2>Asset Management</h2>
							<p>
								We optimize your digital assets for fast and efficient delivery. We configure your app to use the unlimited storage of <a href="https://aws.amazon.com/s3/">AWS S3</a>. And we set up fast content delivery networks like <a href="https://aws.amazon.com/cloudfront/">AWS CloudFront</a>. Your clients get fast downloads no matter where they are in the world.
							</p>
						</div>
			    }
			    textRight={
			      <div>
						<img src="/images/development/mobile-app-marketing.jpg" className="rounded-image" alt="Software Development Company - Custom Programming - Websuasion" />
			      </div>
			    }
			  />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
							<h2>Ongoing Support</h2>
							<p>
								We manage your VPS servers, installing security updates upon release. We track your server uptime so we know the minute something goes wrong. We provide daily data backups. And we track every line of code for both your mobile and server using Git version management.
							</p>
						</div>
	        }
	      />
			</PageWrapper>
			<PageWrapper color="white">
	      <SingleColumnBlock
	        textPadded
	        text={
	          <div>
	            <h4>Let's Talk!</h4>
	            <p>"Data. Tech. Team." Those are the words we live by at websuasion. Our services foster growth and scalability for your business - all while maintaining your brand. We are an indispensable ally for you as you navigate online marketing. Let's talk. We'd love to know more about what you do and how we can solve your pain points.</p>
	            <RequestMoreInfo />
	          </div>
	        }
	      />
			</PageWrapper>
    </PageLayout>
  )
}

export default WebsiteDesignSEOServicePage
